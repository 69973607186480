import config from '../App.config';
import {
    getFirestore,
    getDocs,
    collection,
    where,
    orderBy,
    startAfter,
    limit,
    doc,
    updateDoc,
    setDoc,
    getDoc,
    deleteDoc,
    onSnapshot
} from '@firebase/firestore';
import { query } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from '@firebase/storage';
import Firebase from './Firebase';
import {getMandatoryUiLangProp2, makeid} from "./Firebase";
import {store} from "../redux/Store";
import {setAssetsLibraryListenerInitialised, setAssetsLibrary} from "../redux/AssetsLibraryReducer";

export const startAssetsLibraryListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/assetspreview`;
    const ref = collection(getFirestore(), path);

    const q = query(ref);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const assets = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedAsset = {
                id: doc.id || '',
                name: data.name || '',
                type: data.type || 'text'
            };

            if (data.i18n && data.i18n.name) {
                parsedAsset.i18n = {name: data.i18n.name};
            }

            if (data.downloadUrl) {
                parsedAsset.downloadUrl = data.downloadUrl;
            }

            assets.push(parsedAsset);
        });

        assets.sort((a, b) => {
            const nameA = getMandatoryUiLangProp2(a, 'name');
            const nameB = getMandatoryUiLangProp2(b, 'name');
            return nameA.localeCompare(nameB);
        });

        setUnsubscribe("ASSETS_UNSUB_FUN", unsubscribe);
        store.dispatch(setAssetsLibrary(assets));
        store.dispatch(setAssetsLibraryListenerInitialised(true));
    });
};

// ---  ------------------------------------------------------------------
export const getMyAsset = async (id) => {
  const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/assets/${id}`;
  const ref = doc(getFirestore(),path)
  const snap = await getDoc(ref);
  if (snap.data()) {
      return {...emptyAsset(), ...snap.data(), id: snap.id};
  } else {
      throw new Error("Entity not found");
  }
}

// ---  ------------------------------------------------------------------
export const getMyHeros = async () => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/assets`;
    const _ref = collection(getFirestore(), path)
    const q = await query(_ref, where('type', 'in', ['storageImage', 'ytvideo']));
    const querySnapshot = await getDocs(q);
    const ret = querySnapshot.docs.map(doc => {
      return { ...doc.data(), id: doc.id }
    })
    const storage = getStorage(Firebase.app)
    ret.forEach(async asset => {
      const storageRef = ref(storage, asset.heroImagePath)
      asset._downloadUrl = await getDownloadURL(storageRef);
    })
    return ret
}

// ---  ------------------------------------------------------------------
export const getMyTutorials = async () => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/assets`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref, where('type', 'in', ['storageImage', 'ytvideo', 'text']));
    const querySnapshot = await getDocs(q);
    const ret = querySnapshot.docs.map(doc => {
      return { ...doc.data(), id: doc.id }
    })
    return ret
}

// ---  ------------------------------------------------------------------
export const getMyExtras = async () => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/assets`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref, where('type', 'in', ['link', 'text']));
    const querySnapshot = await getDocs(q);
    const ret = querySnapshot.docs.map(doc => {
      return { ...doc.data(), id: doc.id }
    })
    return ret
}

// ---------------------------------------------------------------------
export const getAssetTypes = () => {
  return ['text', 'ytvideo', 'storageImage', 'link'];
}

// ---------------------------------------------------------------------
export const emptyAsset = () => {
  return {
    name: '',
    description: '',
    type: 'text',
    startTime: 0,
    ytId: '',
    link: '',
  }
}
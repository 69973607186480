import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidebarState {
    isMobileSidebarOpen: boolean;
    isLandscapeSidebarCollapsed: boolean;
}

const initialState: SidebarState = {
    isMobileSidebarOpen: false,
    isLandscapeSidebarCollapsed: false,
};

const sidebarSlice = createSlice({
    name: 'sidebarSlice',
    initialState,
    reducers: {
        setMobileSidebarOpen(state, action: PayloadAction<boolean>) {
            state.isMobileSidebarOpen = action.payload;
        },
        setLandscapeSidebarCollapsed(state, action: PayloadAction<boolean>) {
            state.isLandscapeSidebarCollapsed = action.payload;
        },
    },
});

export const { setMobileSidebarOpen, setLandscapeSidebarCollapsed } = sidebarSlice.actions;
export default sidebarSlice.reducer;
import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import chatReducer from './chats/ChatReducer';
import notesReducer from './notes/NotesReducer';
import emailReducer from './email/EmailReducer';
import userReducer from './UserReducer'
import exercisesReducer from './ExercisesReducer'
import ExercisesLibraryReducer from './ExercisesLibraryReducer';
import workoutsReducer from "./WorkoutsReducer";
import WorkoutsLibraryReducer from './WorkoutsLibraryReducer';
import tagsReducer from "./TagsReducer";
import assetsReducer from "./AssetsReducer";
import PlansReducer from './PlansReducer';
import PlansLibraryReducer from './PlansLibraryReducer';
import routinesReducer from "./RoutinesReducer";
import RoutinesLibraryReducer from "./RoutinesLibraryReducer"
import AthletesReducer from './AthletesReducer';
import AthletesInterviewsReducer from './AthletesInterviewsReducer';
import AssetsLibraryReducer from './AssetsLibraryReducer';
import SidebarReducer from 'src/ts/redux/SidemenuReducer';
import AccountMenuReducer from 'src/ts/redux/AccountMenuReducer';
import ObjectivesReducer from './ObjectivesReducer';
import ActionNotificationReducer from 'src/ts/redux/ActionNotificationReducer';


const RootReducers = combineReducers({
  CustomizerReducer,
  chatReducer,
  notesReducer,
  emailReducer,
  sidebarReducer: SidebarReducer,
  accountMenuReducer: AccountMenuReducer,
  actionNotificationReducer: ActionNotificationReducer,
  user: userReducer,
  exercises: exercisesReducer,
  exercisesLibrary: ExercisesLibraryReducer,
  workouts: workoutsReducer,
  workoutsLibrary: WorkoutsLibraryReducer,
  tags: tagsReducer,
  routines: routinesReducer,
  routinesLibrary: RoutinesLibraryReducer,
  assets: assetsReducer,
  assetsLibrary: AssetsLibraryReducer,
  plans: PlansReducer,
  plansLibrary: PlansLibraryReducer,
  athletes: AthletesReducer,
  athletesInterviews: AthletesInterviewsReducer,
  objectives: ObjectivesReducer,
});

export default RootReducers;

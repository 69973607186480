import config from '../App.config';
import {collection, doc, setDoc, getDoc, getFirestore, onSnapshot} from '@firebase/firestore';
import {store} from "../redux/Store";
import {setUser, setUserListenerInitialised} from "../redux/UserReducer";

export const startUserListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/yhrs-general`;
    const ref = doc(getFirestore(), path);

    const unsubscribe = onSnapshot(ref, (docSnapshot) => {
        if (docSnapshot.exists()) {
            let userDoc = docSnapshot.data();

            const user = {};
            user.uid = uid;
            user.settings = {};
            user.settings.langs = userDoc?.contentLangs || ['en'];
            user.settings.uiLang = userDoc?.uiLang || 'en';

            store.dispatch(setUser(user));
        } else {
            console.log("No such document!");
        }

        setUnsubscribe("USER_UNSUB_FUN", unsubscribe);
        store.dispatch(setUserListenerInitialised(true));
    });
};

export const getEmptyMyAssetRef = (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets`;
    const colRef = collection(getFirestore(), path);
    return doc(colRef);
};

export const getMySettings = async (uid, name) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/${name}`;
    const ref = doc(getFirestore(), path);
    const snap = await getDoc(ref);
    return snap.data() || {}
}

export const putMySettings = async (uid, name, document) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/${name}`;
    const ref = doc(getFirestore(), path);
    await setDoc(ref, document);
}

export const getCoachBillings = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/coach-billing`;
    const ref = doc(getFirestore(), path);
    const snap = await getDoc(ref);
    return snap.data() || {}
}

export const putCoachBillings = async (uid, billingData) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/coach-billing`;
    const ref = doc(getFirestore(), path);
    await setDoc(ref, billingData);
}

export const getCoachResumeDocRef = (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/coach-resume`;
    return doc(getFirestore(), path);
}

export const getCoachResumeData = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/coach-resume`;
    const ref = doc(getFirestore(), path);
    const snap = await getDoc(ref);
    return snap.data() || {}
}

export const putCoachResume = async (uid, resumeData) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/coach-resume`;
    const ref = doc(getFirestore(), path);
    await setDoc(ref, resumeData);
}

export const mergeCoachResume = async (uid, resumeData) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/coach-resume`;
    const ref = doc(getFirestore(), path);
    await setDoc(ref, resumeData, { merge: true });
}
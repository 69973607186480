import config from '../App.config';

import {
collection,
doc, 
getDoc, 
getDocs, 
getFirestore, 
onSnapshot} from '@firebase/firestore';

import {query} from "firebase/firestore";

import {getMandatoryUiLangProp2, makeid} from './Firebase'
import {store} from "../redux/Store";
import {setRoutineLibraryListenerInitialised, setRoutinesLibrary} from "../redux/RoutinesLibraryReducer";

export const startRoutinesLibraryListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/routinespreview/`;
    const ref = collection(getFirestore(), path);

    const q = query(ref);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const routines = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedRoutine = {
                id: doc.id || '',
                name: data.name || '',
                tags: data.tags || [],
                type: data.type || ''
            };

            if (data.i18n && data.i18n.name) {
                parsedRoutine.i18n = {name: data.i18n.name};
            }

            routines.push(parsedRoutine);
        });

        routines.sort((a, b) => {
            const nameA = getMandatoryUiLangProp2(a, 'name');
            const nameB = getMandatoryUiLangProp2(b, 'name');
            return nameA.localeCompare(nameB);
        });

        setUnsubscribe("ROUTINES_UNSUB_FUN", unsubscribe);
        store.dispatch(setRoutinesLibrary(routines));
        store.dispatch(setRoutineLibraryListenerInitialised(true));
    });
};

export const getMyRoutine = async (id) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/routines/${id}`;
    const ref = doc(getFirestore(), path)
    const snap = await getDoc(ref);
    if (snap.data()) {
        return {...snap.data(), id: snap.id};
    } else {
        throw new Error("Entity not found");
    }
}

export const getMyRoutines = async () => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/routines`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref);
    const querySnapshot = await getDocs(q);
    const ret = querySnapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
    })
    return ret
}

export const getRoutineTypes = () => {
    return ['seriesv2', 'circuitv2', 'tabatav2', 'pyramidv2', 'wworkoutv2', 'amrapv2', 'emomv2', 'punisher'];
}


export const emptyRoutine = () => {
    return {
        guid: makeid(),
        type: 'seriesv2',
        exercises: [{guid: makeid(), type: 'duration', tutorials: [], tags: []}],
        punishment: [{guid: makeid(), type: 'duration', tutorials: [], tags: []}],
        challenge: {guid: makeid(), type: 'duration', tutorials: [], tags: []},
        round: [{guid: makeid(), type: 'duration', tags: []}],
        rounds: [{guid: makeid(), exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []}}],
        series: [{guid: makeid(), exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []}}],
        loop: false
    }
}
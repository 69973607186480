import _ from 'lodash';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import typography from './Typography';
import components from './Override';
import shadows from './Shadows';

const yhrsTheme = {
  name: 'YourHeroesTheme',

  direction: 'ltr',

  palette: {
    mode: 'dark',
    background: {
      default: '#272727',
      default2: '#272727EE',
      defaultBlurred: '#27272780',
      paper: '#282C34',
      paperBlurred: '#282C34CC',
      paperTransparent: '#282C34F2',
      paperAlt: '#474B51',
      lightaction: '#06C8D5',
      hardaction: '#EE573B',
    },
    text: {
      primary: '#fff',
      secondary: '#5AB4AC',
      lightaction: '#06C8D5',
      success: '#E69F00',
      alert: '#EE573B',
    },
  },

  shape: {
    borderRadius: 3,
  },

  status: {
    danger: '#e53e3e',
  },

  components,
  typography,
  shadows,

}

export const BuildTheme = (config = {}) => {
  //let themeOptions = themesOptions.find((theme) => theme.name === config.theme);
  let themeOptions = {};
  const customizer = useSelector((state) => state.CustomizerReducer);

  // const baseMode = {
  //   palette: {
  //     mode: customizer.activeMode,
  //     background: {
  //       default: customizer.activeMode === 'dark' ? '#20232a' : '#fafbfb',
  //       dark: customizer.activeMode === 'dark' ? '#1c2025' : '#ffffff',
  //       paper: customizer.activeMode === 'dark' ? '#282C34' : '#ffffff',
  //     },
  //     text: {
  //       primary: customizer.activeMode === 'dark' ? '#e6e5e8' : 'rgba(0, 0, 0, 0.87)',
  //       secondary: customizer.activeMode === 'dark' ? '#adb0bb' : '#777e89',
  //     },
  //   },
  // };
  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    //[themeOptions] = themesOptions;
  }

  const theme = createTheme(
    _.merge({}, yhrsTheme, themeOptions, {
      direction: config.direction,
    }),
  );
  return theme;
};

//export { TopbarHeight, SidebarWidth, yhrsTheme };

import config from '../App.config';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from '@firebase/firestore';
import { setAthletes, setAthletesListenerInitialised } from '../redux/AthletesReducer';
import { store } from '../redux/Store';
import * as yup from 'yup';
import { clearCollection, getCollection, putCollection } from './Plans';
import moment from '../helpers/moment';
import { cleanWorkout, getMyWorkout } from './Workouts';
import { fetchAsset } from './Assets';
import { overwriteAllRoutinesWithFullExercises } from './Routines';
import { getMandatoryUiLangProp2, makeid } from './Firebase';
import { setObjectives, setObjectivesListenerInitialised } from '../redux/ObjectivesReducer';

// ---  ------------------------------------------------------------------
export const getAthletes = async (uid) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/`;
  const ref = collection(getFirestore(), path);
  const q = await query(ref, where('isDeleted', '==', false));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};

// ---  ------------------------------------------------------------------
export const putAthlete = async (uid, data) => {
  const id = data.id || makeid();
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${id}`;
  const ref = doc(getFirestore(), path);
  await setDoc(ref, data, { merge: true });
  return id;
};

// ---  ------------------------------------------------------------------
export const removeAthlete = async (uid, id) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${id}`;
  const ref = doc(getFirestore(), path);
  await setDoc(ref, { isDeleted: true }, { merge: true });
};

// ---  ------------------------------------------------------------------
export const getAthlete = async (uid, id) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${id}`;
  const ref = doc(getFirestore(), path);
  const snap = await getDoc(ref);
  if (snap.data()) {
    return { ...snap.data(), id: snap.id };
  } else {
    throw new Error('Entity not found');
  }
};

// ---  ------------------------------------------------------------------

export const startObjectivesListener = (uid, traineeId, setUnsubscribe) => {
  if (this.unsubscribeFunctions['OBJECTIVES_UNSUB_FUN']) {
    this.unsubscribeFunctions['OBJECTIVES_UNSUB_FUN']();
  }

  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${traineeId}/objectivespreview`;
  const ref = collection(getFirestore(), path);
  const q = query(ref);

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const objectives = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();

      let parsedObjective = {
        id: doc.id || '',
        name: data.name || '',
        tagIds: data.tagIds || [],
      };

      if (data.i18n && data.i18n.name) {
        parsedObjective.i18n = { name: data.i18n.name };
      }

      objectives.push(parsedObjective);
    });

    objectives.sort((a, b) => {
      const nameA = getMandatoryUiLangProp2(a, 'name');
      const nameB = getMandatoryUiLangProp2(b, 'name');
      return nameA.localeCompare(nameB);
    });

    setUnsubscribe('OBJECTIVES_UNSUB_FUN', unsubscribe);
    store.dispatch(setObjectives(objectives));
    store.dispatch(setObjectivesListenerInitialised(true));
  });
};

// ---  ------------------------------------------------------------------
export const getObjective = async (uid, aid, oid, tagsSelector) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/objectives/${oid}`;
  const ref = doc(getFirestore(), path);
  const snap = await getDoc(ref);
  if (snap.data() && !snap.data()?.isDeleted) {
    const fullTags = tagsSelector?.tags?.filter((tag) => snap.data().tagIds?.includes(tag.id));
    const a = await getCollection(
      `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/objectives/${oid}/activities`,
    );
    return { ...snap.data(), activities: a, id: snap.id, tags: fullTags };
  } else {
    throw new Error('Entity not found');
  }
};

// ---  ------------------------------------------------------------------
export const getObjectives = async (uid, aid, tagsSelector) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/objectives`;
  const ref = collection(getFirestore(), path);
  const q = await query(ref);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs
    .filter((d) => d.data().isDeleted !== true)
    .map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
};

// ---  ------------------------------------------------------------------
export const putObjective = async (uid, aid, data, tagsSelector) => {
  const id = data.id || makeid();
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/objectives/${id}`;
  const ref = doc(getFirestore(), path);

  const cleanedData = cleanObjective(data);
  cleanedData.tagIds = cleanedData.tags ? cleanedData.tags.map((tag) => tag.id) : [];

  const previewData = {
    name: cleanedData.name,
    tagIds: cleanedData.tagIds,
  };
  if (cleanedData.i18n && cleanedData.i18n.name) {
    previewData.i18n = { name: cleanedData.i18n.name };
  }
  const previewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/objectivespreview/${id}`;
  const previewRef = doc(getFirestore(), previewPath);
  await setDoc(previewRef, previewData, { merge: true });

  delete cleanedData.tags;
  delete cleanedData.activities;
  await setDoc(ref, cleanedData, { merge: true });

  const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/objectives/${id}/activities`;
  if (cleanedData.id) await clearCollection(a); // fetch all template data
  const cleanedActivities = await cleanActivities(uid, data.activities);

  await putCollection(a, cleanedActivities);
  return id;
};

const cleanActivities = async (uid, activities) => {
  return await Promise.all(
    activities.map(async (activityWrapper) => {
      // activityWrapper zawiera pole 'activity'

      const _data = cleanWorkout(activityWrapper.activity);
      _data.tagIds = _data.tags ? _data.tags.map((tag) => tag.id) : [];

      if (_data.heroAsset?.id) {
        const heroAsset = await fetchAsset(uid, _data.heroAsset.id);
        if (heroAsset) _data.heroAsset = heroAsset;
      }

      if (Array.isArray(_data.tutorials)) {
        _data.tutorials = (
          await Promise.all(
            _data.tutorials.map(async (item) => (item.id ? await fetchAsset(uid, item.id) : null)),
          )
        ).filter((item) => item !== null);
      }

      if (Array.isArray(_data.extras)) {
        _data.extras = (
          await Promise.all(
            _data.extras.map(async (item) => (item.id ? await fetchAsset(uid, item.id) : null)),
          )
        ).filter((item) => item !== null);
      }

      delete _data.tags;

      await overwriteAllRoutinesWithFullExercises(uid, _data);

      return {
        ...activityWrapper,
        activity: _data,
      };
    }),
  );
};

export const cleanObjective = (o) => {
  const ret = { ...o };
  delete ret.template;
  delete ret.type;
  delete ret.selectedDay;
  //console.debug('Cleaned objective data:', ret);
  return ret;
};

// ---  ------------------------------------------------------------------
export const removeObjective = async (uid, aid, oid) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/objectives/${oid}`;
  const ref = doc(getFirestore(), path);
  await setDoc(ref, { isDeleted: true }, { merge: true });
};

// ---  ------------------------------------------------------------------
export const emptyObjective = () => {
  return {
    status: 'new',
    name: '',
    description: '',
    type: null,
    template: null,
    activities: [],
  };
};

// ---  ------------------------------------------------------------------
export const emptyMeasure = () => {
  return {
    createDate: null,
    height: null,
    weight: null,
    neckMeasurement: null,
    chestMeasurement: null,
    leftArmMeasurement: null,
    rightArmMeasurement: null,
    waistMeasurement: null,
    hipsMeasurement: null,
    leftThighMeasurement: null,
    rightThighMeasurement: null,
    leftCalfMeasurement: null,
    rightCalfMeasurement: null,
    underbustMeasurement: null,
    tricepsSkinfold: null,
    suprailiacSkinfold: null,
    thighSkinfold: null,
    abdominalSkinfold: null,
    chestSkinfold: null,
  };
};

export const getMeasure = async (uid, aid, date) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/measures/measurement-${date}`;
  const ref = doc(getFirestore(), path);
  const snap = await getDoc(ref);
  if (snap.data()) {
    return { ...snap.data(), id: snap.id };
  } else {
    throw new Error('No documents found');
  }
};

export const getNewestMeasure = async (uid, aid) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/measures`;
  const ref = collection(getFirestore(), path);

  const q = query(ref, orderBy('createDate', 'desc'), limit(1));
  const snap = await getDocs(q);

  if (!snap.empty) {
    const newestDoc = snap.docs[0];
    return { ...newestDoc.data(), id: newestDoc.id };
  } else {
    console.error('Entity not found, doc dont exist');
  }
};

// ---  ------------------------------------------------------------------
export const putMeasure = async (uid, aid, data, date) => {
  const id = `measurement-${date}`;
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/${aid}/measures/${id}`;
  const ref = doc(getFirestore(), path);
  await setDoc(ref, data, { merge: true });
  return id;
};

// ---  ------------------------------------------------------------------
export const getAthleteSchema = (t) =>
  yup
    .object()
    .shape({
      firstName: yup
        .string()
        .required(t('Required data'))
        .min(3, t('Min 3 chars'))
        .max(255, t('Max 255 chars')),
      lastName: yup
        .string()
        .required(t('Required data'))
        .min(3, t('Min 3 chars'))
        .max(255, t('Max 255 chars')),
      dateOfBirth: yup.string().required(t('Required data')),
      gender: yup.string().required(t('Required data')),
      email: yup.string().email(t('Invalid email address')),
      phone: yup.string(),
    })
    .required();

// ---  ------------------------------------------------------------------
export const emptyAthlete = () => {
  return {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: 'undefined',
    email: '',
    phone: '',
    isDeleted: false,
  };
};

// ---  ------------------------------------------------------------------
export const startAthletesListener = (uid, setUnsubscribe) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/coachclients/`;
  const ref = collection(getFirestore(), path);
  const q = query(ref, where('isDeleted', '==', false));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ id: doc.id, ...doc.data() });
    });
    items.sort((a, b) => {
      return a?.firstName?.localeCompare(b?.firstName);
    });

    setUnsubscribe('ATHLETES_UNSUB_FUN', unsubscribe);
    store.dispatch(setAthletes(items));
    store.dispatch(setAthletesListenerInitialised(true));
  });
};

// --- transform Plan to Activities in Athlete's Objective -----------------------------------------------------------------
// export const transformPlanToObjectiveActivities = async (uid, pid) => {
//     const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${pid}/activities`;
//     const activities = await getCollection(path);
//     const ret = activities.map((item) => {
//         return { ...item.activity, scheduleDate: moment().add(item.scheduleOrder - 1,'days').format('YYYY-MM-DD') }
//     });
//     return ret;
// }

// --- transform Workout to Activities in Athlete's Objective -----------------------------------------------------------------
export const transformWorkoutToObjectiveActivities = async (uid, wid) => {
  const w = getMyWorkout(uid, wid);
  const ret = [
    {
      ...w,
      scheduleDate: moment()
        .add(w.scheduleOrder - 1, 'days')
        .format('YYYY-MM-DD'),
    },
  ];
  return ret;
};

// ---  ------------------------------------------------------------------

const FIRESTORE_ROOT = "v1/dev";
const FIRESTORE_CONTENT_LIBRARY = FIRESTORE_ROOT + "/contentLibrary";

const dev = {
    FIRESTORE_ROOT,
    FIRESTORE_CONTENT_LIBRARY,
    FIRESTORE_MOBILEAPP_SETTINGS_DOC: `${FIRESTORE_CONTENT_LIBRARY}/mobileappSettings`,

    FUNCTIONS_REGION: "europe-west3",

    apiKey: "AIzaSyB9EPWgyuzEFTeuPxhoO39WT9Fx1ZQ5dG4",
    authDomain: "everydaybetter-1bb84.firebaseapp.com",
    databaseURL: "https://everydaybetter-1bb84.firebaseio.com",
    projectId: "everydaybetter-1bb84",
    storageBucket: "everydaybetter-1bb84.appspot.com",
    messagingSenderId: "218916602884",
    webApiKey: "AIzaSyB9EPWgyuzEFTeuPxhoO39WT9Fx1ZQ5dG4"
}
  
const live = {
    FIRESTORE_ROOT,
    FIRESTORE_CONTENT_LIBRARY,
    FIRESTORE_MOBILEAPP_SETTINGS_DOC: `${FIRESTORE_CONTENT_LIBRARY}/mobileappSettings`,

    FUNCTIONS_REGION: "europe-west3",

    apiKey: "AIzaSyAKZVVvePetnxkbfBCqCmlw0ileV8lea_0",
    authDomain: "youhero-be-live.firebaseapp.com",
    databaseURL: "https://youhero-be-live.firebaseio.com",
    projectId: "youhero-be-live",
    // storageBucket: "everydaybetter-1bb84.appspot.com",
    // messagingSenderId: "218916602884"
    webApiKey: "tobeset"
}
  
const config = {
    //...(process?.env?.REACT_APP_STAGE === 'live' ? live : dev)
    ...(dev)
}

export default config;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AccountMenuState {
    isOpen: boolean;
}

const initialState: AccountMenuState = {
    isOpen: false,
};

const accountMenuSlice = createSlice({
    name: 'accountMenuSlice',
    initialState,
    reducers: {
        setAccountMenuOpen(state, action: PayloadAction<boolean>) {
            state.isOpen = action.payload;
        },
    },
});

export const { setAccountMenuOpen } = accountMenuSlice.actions;
export default accountMenuSlice.reducer;
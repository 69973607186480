import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionNotificationProps } from '../models/_global/actionbarmodels';

interface ActionNotificationState {
    notifications: ActionNotificationProps[];
}

const initialState: ActionNotificationState = {
    notifications: [],
};

const actionNotificationSlice = createSlice({
    name: 'actionNotification',
    initialState,
    reducers: {
        addActionNotification(state, action: PayloadAction<ActionNotificationProps>) {
            state.notifications.push(action.payload);
        },

        removeActionNotification(state, action: PayloadAction<string>) {
            state.notifications = state.notifications.filter(
                (notification) => notification.id !== action.payload
            );
        },
    },
});

export const { addActionNotification, removeActionNotification } = actionNotificationSlice.actions;

export default actionNotificationSlice.reducer;
import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    objectives: [],
    objectivesListenerInitialised: false
};

export const objectivesSlice = createSlice({
    name: 'objectivesListener',
    initialState,
    reducers: {
        setObjectives: (state, action) => {
            state.objectives = action.payload
        },
        setObjectivesListenerInitialised: (state, action) => {
            state.objectivesListenerInitialised = action.payload
        }
    },
});

export const {setObjectives} = objectivesSlice.actions;
export const {setObjectivesListenerInitialised} = objectivesSlice.actions;
export const selectObjectives = (state) => state.objectives;

export default objectivesSlice.reducer;

import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import {Router} from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import FirebaseContext from './Firebase/FirebaseContext';
import Firebase from './Firebase/Firebase';
import './components/i18n';
import ErrorBoundaryLocal from './ErrorBoundaryLocal';

const App = () => {
  
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  
  return (
    <FirebaseContext.Provider value={Firebase}>
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          <ErrorBoundaryLocal>
            {routing}
          </ErrorBoundaryLocal>
        </RTL>
      </ThemeProvider>
    </FirebaseContext.Provider>
  );
};
export default App;

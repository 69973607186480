import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';


const getDefaultLang = () => {
  return (
    localStorage.getItem("savedUILang") ||  
    navigator.language?.split("-")[0] || 
    "en"
  );
};

export const setI18NUILanguage = async (lang) => {
  localStorage.setItem("savedUILang", lang);
  await i18n.changeLanguage(lang);
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .init(
    {
      fallbackLng: 'en',
      debug: false,
      lng: getDefaultLang(),
      backend: {
        loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json`,
      },
    },
    (err) => {
      if (err) {
        console.error('Błąd podczas inicjalizacji i18n:', err);
        return;
      }

      i18n.loadLanguages(['en', 'pl'], (error) => {
        if (error) {
         // console.error('Błąd przy ładowaniu języków:', error);
        } else {
         // console.log('Załadowano języki: en, pl');
        }
      });
    }
  );

export default i18n;
import config from '../App.config';
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  updateDoc,
  query,
  deleteDoc,
  startAfter,
  limit,
  orderBy,
  onSnapshot,
} from '@firebase/firestore';
import * as yup from 'yup';
import { store } from '../redux/Store';
import { getMandatoryUiLangProp2, makeid } from './Firebase';
import { setPlans, setPlansListenerInitialised } from '../redux/PlansReducer';
import { cleanWorkout, getMyWorkout } from './Workouts';
import { fetchAsset } from './Assets';
import { overwriteAllRoutinesWithFullExercises } from './Routines';

export const startPlansListener = (uid, setUnsubscribe) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplanspreview`;
  const ref = collection(getFirestore(), path);
  const q = query(ref);

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const plans = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();

      let parsedPlan = {
        id: doc.id || '',
        name: data.name || '',
        tagIds: data.tagIds || [],
      };

      if (data.i18n && data.i18n.name) {
        parsedPlan.i18n = { name: data.i18n.name };
      }

      plans.push(parsedPlan);
    });

    plans.sort((a, b) => {
      const nameA = getMandatoryUiLangProp2(a, 'name');
      const nameB = getMandatoryUiLangProp2(b, 'name');
      return nameA.localeCompare(nameB);
    });

    setUnsubscribe('PLANS_UNSUB_FUN', unsubscribe);
    store.dispatch(setPlans(plans));
    store.dispatch(setPlansListenerInitialised(true));
  });
};

// ---  ------------------------------------------------------------------
export const getMyPlans = async (uid) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans`;
  const ref = collection(getFirestore(), path);
  const q = await query(ref);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};

// ---  ------------------------------------------------------------------
export const getMyPlansPage = async (uid, last) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans`;
  const ref = collection(getFirestore(), path);
  let q;
  if (last) {
    q = await query(ref, orderBy('name'), startAfter(last), limit(20));
  } else {
    q = await query(ref, orderBy('name'), limit(20));
  }
  const querySnapshot = await getDocs(q);
  const _last = querySnapshot.size > 0 ? querySnapshot.docs[querySnapshot.size - 1] : null;
  const data = querySnapshot.docs?.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return { data, last: _last };
};

// ---  ------------------------------------------------------------------
export const getMyPlan = async (uid, id, tagsSelector) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${id}`;
  const ref = doc(getFirestore(), path);
  const snap = await getDoc(ref);
  if (snap.data()) {
    const fullTags = tagsSelector?.tags?.filter((tag) => snap.data().tagIds?.includes(tag.id));
    const ret = { ...emptyPlan(), ...snap.data(), id: snap.id, tags: fullTags };
    const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${id}/activities`;
    ret.activities = await getCollection(a);
    addGuidsTo(ret);
    repairSchema(ret);
    return ret;
  } else {
    throw new Error('Entity not found');
  }
};

// ---  ------------------------------------------------------------------
export const addGuidsTo = async (plan) => {
  plan.activities?.forEach((r) => {
    r.guid = makeid();
  });
};

const repairSchema = (w) => {
  w.tags = [...w.tags];
};

// ---  ------------------------------------------------------------------
export const putMyPlan = async (uid, data) => {
  if (data.id) {
    const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${data.id}/activities`;
    await clearCollection(a);
    await putCollection(a, data.activities);
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${data.id}`;
    const ref = doc(getFirestore(), path);
    const _data = cleanPlan(data);
    await updateDoc(ref, _data);
  } else {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans`;
    const ref = doc(collection(getFirestore(), path));
    const _data = cleanPlan(data);
    await setDoc(ref, _data);
    const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${ref.id}/activities`;
    await putCollection(a, data.activities);
  }
};

export const putPlan = async (uid, data) => {
  if (data.id) {
    const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${data.id}/activities`;
    await clearCollection(a);
    await putCollection(a, data.activities);
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${data.id}`;
    const ref = doc(getFirestore(), path);
    const _data = cleanPlan(data);
    _data.tagIds = _data.tags ? _data.tags.map((tag) => tag.id) : [];

    const previewData = {
      name: _data.name,
      tagIds: _data.tagIds,
    };
    if (_data.i18n && _data.i18n.name) {
      previewData.i18n = { name: _data.i18n.name };
    }
    const previewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplanspreview/${data.id}`;
    const previewRef = doc(getFirestore(), previewPath);
    await setDoc(previewRef, previewData, {merge: true})

    delete _data.tags;
    delete _data.activities;
    await updateDoc(ref, _data);
  } else {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans`;
    const ref = doc(collection(getFirestore(), path));
    const _data = cleanPlan(data);
    const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${ref.id}/activities`;
    await putCollection(a, data.activities);
    _data.tagIds = _data.tags ? _data.tags.map((tag) => tag.id) : [];

    const previewData = {
      name: _data.name,
      tagIds: _data.tagIds,
    };
    if (_data.i18n && _data.i18n.name) {
      previewData.i18n = { name: _data.i18n.name };
    }
    const previewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplanspreview/${ref.id}`;
    const previewRef = doc(getFirestore(), previewPath);
    await setDoc(previewRef, previewData, {merge: true})

    delete _data.tags;
    delete _data.activities;
    await setDoc(ref, _data);
  }
};

const cleanActivities = async (uid, activities) => {
  return await Promise.all(
    activities.map(async (activityWrapper) => {
      // activityWrapper zawiera pole 'activity'

      const _data = cleanWorkout(activityWrapper.activity);
      _data.tagIds = _data.tags ? _data.tags.map((tag) => tag.id) : [];

      if (_data.heroAsset?.id) {
        const heroAsset = await fetchAsset(uid, _data.heroAsset.id);
        if (heroAsset) _data.heroAsset = heroAsset;
      }

      if (Array.isArray(_data.tutorials)) {
        _data.tutorials = (
          await Promise.all(
            _data.tutorials.map(async (item) => (item.id ? await fetchAsset(uid, item.id) : null)),
          )
        ).filter((item) => item !== null);
      }

      if (Array.isArray(_data.extras)) {
        _data.extras = (
          await Promise.all(
            _data.extras.map(async (item) => (item.id ? await fetchAsset(uid, item.id) : null)),
          )
        ).filter((item) => item !== null);
      }

      delete _data.tags;

      await overwriteAllRoutinesWithFullExercises(uid, _data);

      return {
        ...activityWrapper,
        activity: _data,
      };
    }),
  );
};

export const cleanMyPlan = (o) => {
  const ret = { ...o };
  delete ret.template;
  delete ret.type;
  delete ret.selectedDay;
  //console.debug('Cleaned objective data:', ret);
  return ret;
};

// ---  ------------------------------------------------------------------
export const removeMyPlan = async (uid, id) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${id}`;
  const ref = doc(getFirestore(), path);

  const previewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${id}`;
  const previewRef = doc(getFirestore(), previewPath);

  console.debug(`removeMyPlan: ${id}`);
  await deleteDoc(ref);
  await deleteDoc(previewRef);
};

// ---  ------------------------------------------------------------------
export const emptyPlan = () => {
  return {
    name: '',
    description: '',
    type: 'plan',
    tags: [],
    // heroAsset: null,
    // tutorials: [],
    // extras: [],
    activities: [emptyDay()],
  };
};

// ---  ------------------------------------------------------------------
export const emptyDay = () => {
  return {
    guid: makeid(),
    scheduleOrder: 1,
  };
};

// ---  ------------------------------------------------------------------
export const planSchema = (t) =>
  yup
    .object()
    .shape({
      name: yup
        .string(t('Pole jest wymagane'))
        .required(t('Pole jest wymagane'))
        .min(3, t('Minimum 3 znaków'))
        .max(255, t('Maximum 255 znaków')),
      description: yup
        .string(t('Pole jest wymagane'))
        .required(t('Pole jest wymagane'))
        .min(3, t('Minimum 3 znaków'))
        .max(1000, t('Maksimum 1000')),
    })
    .required();

// --- clean plan object (before saveing in db) ------------------------------------------------------------------
export const cleanPlan = (plan) => {
  const {
    activities,
    ...ret // assigns remaining keys to a new `ret` object
  } = plan;
  console.debug('Cleaned Plan data:', ret);
  return ret;
};

// --- removes all docs from colleciton  -----------------------------------------------------------------
export const clearCollection = async (path) => {
  const ref = collection(getFirestore(), path);
  const q = await query(ref);
  const querySnapshot = await getDocs(q);
  await Promise.all(
    querySnapshot.docs.map((doc) => {
      return deleteDoc(doc.ref);
    }),
  );
};

// --- generic helper - saves array as collection -----------------------------------------------------------------
export const putCollection = async (path, arr) => {
  await Promise.all(
    arr?.map((item) => {
      const ref = doc(collection(getFirestore(), path));
      return setDoc(ref, item);
    }),
  );
};

// --- generic helper - saves array as collection -----------------------------------------------------------------
export const getCollection = async (path) => {
  const ref = collection(getFirestore(), path);
  const q = await query(ref);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};

// --- creates one-day plan from given workout -----------------------------------------------------------------
export const createPlanFromWorkout = (w) => {
  const plan = emptyPlan();
  plan.activities[0].scheduleOrder = 1;
  plan.activities[0].activity = { ...w, id: makeid() };
  //console.debug('createPlanFromWorkout', plan)
  return plan;
};

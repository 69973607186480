import { createSlice } from '@reduxjs/toolkit';
import {exercisesSlice} from "./ExercisesReducer";

export const initialState = {
  userListenerInitialised: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserMeta: (state, action) => {
      state.meta = action.payload.meta;
    },
    setUser: (state, action) => {
      //state.data = action.payload.data;
      state.settings = action.payload.settings;
      state.uid = action.payload.uid;
      //i18n.changeLanguage(state.settings.uiLang)
    },
    setUserListenerInitialised: (state, action) => {
      state.userListenerInitialised = action.payload
    }
  },
});

export const hasRole = (state, role) => {
  var res = false;
  var roles = [];
  if(state && state.meta && state.meta.roles) {
    res = state.meta.roles.includes(role);
  }
  
  return res;

  //return Array.isArray(state?.meta) && 
};

export const { setUser } = userSlice.actions;
export const { setUserMeta } = userSlice.actions;

export const {setUserListenerInitialised} = userSlice.actions;
export const { logoutUser } = userSlice.actions;


export const selectUser = (state) => state.user;
export const selectUserData = (state) => state.user.data;
export const selectUserMeta = (state) => state.user.meta;
export const selectCoreLang = (state) => {
  const langs = state.user?.settings?.langs || [];
  return langs.length > 0 ? langs[0] : undefined;
}
export const selectUserLangs = (state) => {
  const langs = state.user?.settings?.langs || [];
  return langs.slice(1);
}

export default userSlice.reducer;

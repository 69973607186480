import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    athletesInterviews: [],
    athletesInterviewsListenerInitialised: false
};

export const athletesInterviewsSlice = createSlice({
    name: 'athletesInterviewsListener',
    initialState,
    reducers: {
        setAthletesInterviews: (state, action) => {
            state.athletesInterviews = action.payload
        },
        setAthletesInterviewsListenerInitialised: (state, action) => {
            state.athletesInterviewsListenerInitialised = action.payload
        }
    },
});

export const {setAthletesInterviews} = athletesInterviewsSlice.actions;
export const {setAthletesInterviewsListenerInitialised} = athletesInterviewsSlice.actions;
export const selectAthletesInterviews = (state) => state.athletesInterviews;

export default athletesInterviewsSlice.reducer;

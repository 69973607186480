import config from '../App.config';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter
} from '@firebase/firestore';

import { store } from "../redux/Store";
import { setExerciseLibraryListenerInitialised, setExercisesLibrary } from "../redux/ExercisesLibraryReducer";
import { getMandatoryUiLangProp2 } from "./Firebase";


export const startExercisesLibraryListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/exercisespreview/`;
    const ref = collection(getFirestore(), path);

    const q = query(ref);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const exercisesLibrary = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedExercise = {
                id: doc.id || '',
                name: data.name || '',
                tagIds: data.tagIds || [],
                type: data.type || 'duration',
                tempo: data.tempo || '2-0-2-0'
            };


            if (data.i18n && data.i18n.name) {
                parsedExercise.i18n = {name: data.i18n.name};
            }

            exercisesLibrary.push(parsedExercise);
        });

        exercisesLibrary.sort((a, b) => {
            const nameA = getMandatoryUiLangProp2(a, 'name');
            const nameB = getMandatoryUiLangProp2(b, 'name');
            return nameA.localeCompare(nameB);
        });

        setUnsubscribe("EXERCISESLIBRARY_UNSUB_FUN", unsubscribe);
        store.dispatch(setExercisesLibrary(exercisesLibrary));
        store.dispatch(setExerciseLibraryListenerInitialised(true));
        
    });
};

// ---  ------------------------------------------------------------------
export const getMyExercises = async () => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/exercises`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
    })
}

// ---  ------------------------------------------------------------------
export const getMyExercise = async (id) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/exercises/${id}`;
    const ref = doc(getFirestore(), path)
    const snap = await getDoc(ref);
    console.debug("snap:"+snap.exists()+" id:"+id);
    if (snap.data()) {
        return {...snap.data(), id: snap.id};
    } else {
        throw new Error("Entity not found");
    }
}

// ---  ------------------------------------------------------------------
export const getExerciseTypes = () => {
    return ['duration', 'reps', 'max'];
}

// ---  ------------------------------------------------------------------
export const emptyExercise = {
    name: '',
    description: '',
    type: 'duration',
    tags: [],
    reps: 0,
    duration: 0,
    max: 0,
    load: 0,
    tutorials: []
}
const hWeight = 700;
const rWeight = 500;
const lWeight = 300;

const xlFontSize = '1.5rem';
const lFontSize = '1.125rem';
const mFontSize = '1rem';
const sFontSize = '0.875rem';
const xsFontSize = '0.75rem';

const typography = {
  fontFamily: "'Poppins', sans-serif",
  htmlFontSize: 16,
  fontSize: 16,
  body1: {
    fontWeight: 400,
  },
  h1: {
    fontWeight: 500,
    fontSize: '1.875rem',
    lineHeight: '1.5',
  },
  h2: {
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '1.5',
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.3125rem',
    lineHeight: '1.5',
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.5',
  },
  h5: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  h6: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.5',
  },
  button: {
    textTransform: 'none',
    fontWeight: '400',
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: '400',
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: '400',
  },

  xlHeavyStyle: {
    fontSize: xlFontSize,
    fontWeight: hWeight,
  },
  xlRegularStyle: {
    fontSize: xlFontSize,
    fontWeight: rWeight,
  },
  xlLightStyle: {
    fontSize: xlFontSize,
    fontWeight: lWeight,
  },

  lHeavyStyle: {
    fontSize: lFontSize,
    fontWeight: hWeight,
  },
  lRegularStyle: {
    fontSize: lFontSize,
    fontWeight: rWeight,
  },
  lLightStyle: {
    fontSize: lFontSize,
    fontWeight: lWeight,
  },

  mHeavyStyle: {
    fontSize: mFontSize,
    fontWeight: hWeight,
  },
  mRegularStyle: {
    fontSize: mFontSize,
    fontWeight: rWeight,
  },
  mLightStyle: {
    fontSize: mFontSize,
    fontWeight: lWeight,
  },

  sHeavyStyle: {
    fontSize: sFontSize,
    fontWeight: hWeight,
  },
  sRegularStyle: {
    fontSize: sFontSize,
    fontWeight: rWeight,
  },
  sLightStyle: {
    fontSize: sFontSize,
    fontWeight: lWeight,
  },

  xsHeavyStyle: {
    fontSize: xsFontSize,
    fontWeight: hWeight,
  },
  xsRegularStyle: {
    fontSize: xsFontSize,
    fontWeight: rWeight,
  },
  xsLightStyle: {
    fontSize: xsFontSize,
    fontWeight: lWeight,
  },
  
};

export default typography;
